.FormGroup_inputColor {
  position: relative;
}

.FormGroup_inputColorIcon {
  position: absolute;
  right: 10px;
  top: 9px;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid #e2e2e2;
  z-index: 4;
}
