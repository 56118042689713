.MProduct .modal-body {
  height: 465px;
  overflow-y: auto;
}

.MProduct .table {
  margin-bottom: 0;
}

.MProduct_yandexMarket textarea {
  height: 210px;
}
