.PriceTable input {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  margin: 0;
}

.PriceTable input:focus,
.PriceTable input:active {
  box-shadow: none;
  outline: none;
}

.PriceTable thead td,
.PriceTable thead th {
  text-align: center;
  vertical-align: middle;
}
