.MArticle .modal-body {
  height: 465px;
  overflow-y: auto;
}

.MArticle .GmEditor {
  height: 414px;
}

.MArticle_seo textarea {
  height: 296px;
}
