.MStaticPage .modal-body {
  height: 300px;
  overflow-y: auto;
}

.MStaticPage .GmEditor {
  height: 258px;
}

.MStaticPage_seo textarea {
  height: 140px;
}
