.Sidebar {
  background-color: #2f4050;
  min-width: 64px;
  padding-top: 54px;
}

.Sidebar_item {
  align-items: center;
  color: rgb(167, 177, 194);
  display: flex;
  height: 64px;
  justify-content: center;
  position: relative;
}

.Sidebar_item:hover {
  color: #FFF;
  text-decoration: none;
}

.Sidebar_item_active {
  background-color: rgb(37, 51, 64);
}

.Sidebar_item_active::before {
  background-color: rgb(28, 179, 148);
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 4px;
}
