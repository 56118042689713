.List_button {
  background-color: transparent;
  border: none;
  color: #dee2e6;
}

.List_button:focus,
.List_button:active {
  outline: none;
}

.List .table-success .List_button {
  color: #afb3b7;
}

.List .table-danger .List_button {
  color: #afb3b7;
}

.List_button:hover {
  color: #212529 !important;
}

.List_cellFlex {
  display: flex;
  align-items: center;
}

.List_colorBox {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  margin-top: 3px;
  border-radius: 4px;
  border: 1px solid #e2e2e2;
}
