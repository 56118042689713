html,
body,
#root {
  height: 100%;
}

body {
  background-color: #f3f3f4;
  overflow-x: scroll;
  overflow-y: hidden;
}

.custom-file-label {
  transition: none;
}

.custom-file-label::after {
  content: 'Выбрать' !important;
}

.modal {
  overflow: scroll;
}

.table {
  table-layout: fixed;
}

.table-hover td {
  cursor: pointer;
}

.w-30px {
  width: 30px;
}

.w-100px {
  width: 100px;
}

.w-120px {
  width: 120px;
}

.w-140px {
  width: 140px;
}

.w-150px {
  width: 150px;
}

.w-180px {
  width: 180px;
}

.w-250px {
  width: 250px;
}
