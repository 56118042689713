.MProductCategory .modal-body {
  height: 300px;
  overflow-y: auto;
}

.MProductCategory .GmEditor {
  height: 260px;
}

.MProductCategory_image {
  max-height: 268px;
  max-width: 766px;
}

.MProductCategory_seo textarea {
  height: 140px;
}
