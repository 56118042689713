.MProductSubcategory .modal-body {
  height: 420px;
  overflow-y: auto;
  padding-bottom: 0;
}

.MProductSubcategory .GmEditor {
  height: 340px;
}

.MProductSubcategory_seo textarea {
  height: 270px;
}
