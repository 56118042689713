.MBanner_imageGroup {
  height: 155px;
  margin-bottom: 1rem;
}

.MBanner_image {
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
}

.MBanner_image img {
  max-height: 120px;
}

.MBanner_image:hover img {
  opacity: 0.25;
}

.MBanner_imageButton {
  cursor: pointer;
  display: none;
  position: absolute;
}

.MBanner_image:hover .MBanner_imageButton {
  display: inline-block;
}
