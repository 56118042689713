.ImageList_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-right: -16px;
}

.ImageList_container._drag .ImageListItem:hover .ImageListItem_buttons {
  display: none;
}

.ImageList_item {
  height: 185px;
  margin-bottom: 16px;
  margin-right: 16px;
  width: 179px;
}

.ImageList_input {
  align-items: center;
  cursor: pointer;
  color: rgb(167, 177, 194);
  display: flex;
  height: 100%;
  justify-content: center;
  margin: 0;
}

.ImageList_spinner {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

.ImageList_input:hover {
  opacity: 0.5;
}
