.MNews .modal-body {
  height: 310px;
  overflow-y: auto;
}

.MNews .GmEditor {
  height: 270px;
}

.MNews_image {
  max-width: 270px;
}
