.GmEditor {
  position: relative;
}

.GmEditor_toolbar {
  background: #fff;
  border-bottom: 1px solid #bbb;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  display: flex;
  margin: 1px 1px 0;
  opacity: 0.9;
  padding: 5px;
  position: absolute;
  width: calc(100% - 2px);
}

.GmEditor_button {
  background-color: transparent;
  border: none;
  color: rgba(44, 62, 80, 0.6);
  height: 30px;
  padding: 0;
  width: 30px;
}

.GmEditor_button:hover:not(:disabled),
.GmEditor_button._active {
  color: #2c3e50;
}

.GmEditor_button:focus,
.GmEditor_button:active {
  outline: none;
}

.GmEditor_button:last-child {
  margin-left: auto;
}

.GmEditor_textarea,
.GmEditor_preview {
  border-radius: 0.25rem;
  border: 1px solid #bbb;
  height: 100%;
  overflow-anchor: none;
  padding: 51px 10px 10px;
  width: 100%;
}

.GmEditor_preview {
  background-color: rgba(0, 0, 0, 0.05);
  overflow: scroll;
}

.GmEditor_preview h2 {
  text-align: center;
}

.GmEditor_preview h2, p, ul, ol {
  margin-bottom: 10px;
}

.GmEditor_preview img {
  max-height: 150px;
}

.GmEditor_preview iframe,
.GmEditor_preview .GmVideoStub {
  border: none;
  display: block;
  height: 120px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 173px;
}

.GmEditor_preview .GmCard {
  background: #f9f9f9;
  border-radius: 5px;
  border: 1px solid #ccc;
  display: flex;
  margin-bottom: 10px;
  overflow: hidden;
  padding: 14px 7px;
}

.GmEditor_preview .GmCard_image {
  flex-shrink: 0;
  height: 150px;
  margin-right: 20px;
  text-align: center;
  width: 150px;
}

.GmEditor_preview .GmCard_main {
  width: 100%;
}

.GmEditor_preview .GmCard_title {
  background: #ebecec;
  color: #435159;
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  margin: 0;
  margin-bottom: 5px;
  padding: 5px;
  text-align: center;
  text-transform: uppercase;
}

.GmEditor_preview .GmCard_content h2 {
  font-size: 14px;
}

.GmEditor_preview .GmCard_content {
  font-size: 12px;
}

.GmEditor_preview .GmVideoStub {
  background: #435159;
}

.GmEditor_textarea:active,
.GmEditor_textarea:focus {
  outline: none;
}

.GmImage {
  margin-bottom: 10px;
  text-align: center;
}

.GmRow {
  display: flex;
  justify-content: center;
}

.GmRow > *:not(:last-child) {
  margin-right: 10px;
}

.GmTable {
  margin-bottom: 10px;
}

.GmTable table {
  background: #fff;
  width: 100%;
}

.GmTable table th,
.GmTable table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.GmTable table th {
  border-bottom-width: 2px;
  font-weight: 400;
  text-align: center;
}

.GmTable table td {
  text-align: center;
}

.GmTable tbody tr:nth-of-type(odd) {
  background: #f9f9f9;
}
